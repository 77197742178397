const confirmComponents = (components, cb) => {
  console.info('Checking components exist...');
  // Check that Components have loaded
  if (typeof components !== 'undefined') {
    return cb(null, components);
  }
  return cb('Components failed to load', null);
};

export default confirmComponents;
