// Accessed from global (window) after bundling
import FontFaceObserver from 'fontfaceobserver';

// Expects '_fSetClass' and '_fRemoveClass' to be specified in the global scope (ie. window).
// They are specified here in the page template head, to ensure immediate class addition to minimise FOUT.
const loadFonts = (win, fonts) => {
  if (!(win._fSetClass && win._fRemoveClass)) {
    console.error('Please specify methods to add and remove the fonts-loaded class');
    return;
  }

  const sessionStorage = win.sessionStorage;
  const setClass = win._fSetClass;
  const removeClass = win._fRemoveClass;

  let fontObservers = [];

  Object.keys(fonts).forEach(fontName => {
    Object.keys(fonts[fontName]).forEach(fontVariant => {
      const font = fonts[fontName][fontVariant];
      fontObservers.push(new FontFaceObserver(font['font-family'], {
        weight: font['font-weight'] || 'normal',
        style: font['font-style'] || 'normal'
      }));
    });
  });

  Promise.all(fontObservers.map(observer => observer.load())).then(() => {
    console.info('Fonts successfully loaded');
    sessionStorage.fontsLoaded = true;
    setClass();
  }).catch(err => {
    console.info('Fonts failed to load, see error:', err);
    sessionStorage.fontsLoaded = false;
    removeClass();
  });
};

export default loadFonts;
