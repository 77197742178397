import * as window from 'window'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved

import confirmComponents from './utilities/confirm-components';
import loadFonts from './utilities/load-fonts';

confirmComponents(window && window.BCComponents, (err, components) => {
  if (err) {
    return console.warn(err);
  }

  if (!window.document.querySelectorAll || !window.addEventListener) {
    // doesn't cut the mustard.
    return;
  }

  // Components have loaded and are safe to use
  components.mastheadSearch.init();
  components.mastheadSsNavMain.init();
  components.mastheadSs.init();
  components.navMain.init();
  components.notification.init();
  components.tabs.init();
  components.gallery.init();
  components.tabs.init('[role="tab"][data-role-variant="accordion"]', true);
  components.buttonToggle.init();
  components.admissionForm.init();
});

// $tokens is made available as a var inside the js wrapper in rollup build step
loadFonts(window, $tokens.fontFaces); // eslint-disable-line no-undef
